// src/ProfessionalDetail.jsx
import React, { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DetailReservaProfile from './ProfessionalDetail/DetailReservaProfile';
import DetailReservaReviews from './ProfessionalDetail/DetailReservaReviews';
import DetailReservaGallery from './ProfessionalDetail/DetailReservaGallery';
import ReviewForm from './ReviewForm';
import ProfessionalReservationForm from './ProfessionalReservationForm';
import { auth } from './firebase';
import './ProfessionalDetail.css';

import { ChatContext } from './chat/ChatContext';

const ProfessionalDetail = () => {
  const { id: professionalId } = useParams();
  const user = auth.currentUser;
  const navigate = useNavigate();

  const { setProfessionalId } = useContext(ChatContext);

  const handleStartChat = () => {
    // 1) Forzar un cambio de professionalId para que el chat se "reinicie"
    setProfessionalId(null);

    // 2) En el siguiente ciclo, volvemos a asignar el original
    setTimeout(() => {
      setProfessionalId(professionalId);
    }, 0);
  };

  return (
    <div className="professional-detail-container">
    <div className="left-column">
      <div className="professional-detail-profile">
        <DetailReservaProfile professionalId={professionalId} />
      </div>
      <div className="professional-detail-gallery">
        <DetailReservaGallery professionalId={professionalId} />
      </div>
      <div className="professional-detail-reviews">
        <DetailReservaReviews professionalId={professionalId} />
      </div>
      <div className="review-form">
        <ReviewForm professionalId={professionalId} />
      </div>
    </div>
  
    <div className="right-column">
      <div className="professional-detail-reservation-form">
        <ProfessionalReservationForm professionalId={professionalId} />
      </div>
      
    </div>
  </div>
  
  );
};

export default ProfessionalDetail;
