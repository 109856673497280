import React from 'react';
import './ProfessionalList.css';

const ProfessionalListUser = ({ professionals, onSelectProfessional }) => {
  return (
    <div className="professionallistuser-container">
      <h3 className="professionallistuser-header">Profesionales</h3>
      <ul className="professionallistuser-list">
        {professionals.map((professional) => (
          <li
            key={professional.id}
            className="professionallistuser-item"
            onClick={() => onSelectProfessional(professional.id)}
          >
            <img src={professional.photoUrl} alt={professional.name} />
            <div className="text-container">
              <span className="name">{professional.name}</span>
              <span className="last-message">{professional.lastMessage}</span>
              {professional.unreadMessages > 0 && (
                <span className="unread-count">{professional.unreadMessages}</span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProfessionalListUser;