import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebase';
import { collection, query, where, onSnapshot, doc, getDoc, updateDoc, getDocs } from 'firebase/firestore';
import { sendMessage, subscribeToMessages, uploadChatFile } from '../chatfunctions';
import ConversationList from './components/ConversationList';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';

function ProfessionalChatPage() {
  const [user] = useAuthState(auth); // Profesional autenticado
  const [conversations, setConversations] = useState([]);
  const [selectedConversationId, setSelectedConversationId] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  // Obtener todas las conversaciones donde professionalId == user.uid
  useEffect(() => {
    if (!user) return;
    console.log('Professional UID:', user.uid); // <-- Verificar
    const convRef = collection(db, 'conversations');
    const qConv = query(
      convRef,
      where('professionalId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(qConv, async (snapshot) => {
      const convs = [];
      for (const docSnap of snapshot.docs) {
        const data = docSnap.data();
        console.log('Conversation data:', data); // <-- Verificar datos de la conversación
        const userDoc = await getDoc(doc(db, 'users', data.userId));
        const userName = userDoc.exists() ? userDoc.data().name : 'Usuario desconocido';

        convs.push({
          id: docSnap.id,
          userId: data.userId,
          professionalId: data.professionalId,
          lastMessage: data.lastMessage || '',
          updatedAt: data.updatedAt.toDate().toLocaleString(), // Convertir marca de tiempo a cadena legible
          userName,
        });
      }
      console.log('Conversations:', convs); // <-- Verificar conversaciones obtenidas
      setConversations(convs);
    });

    return () => unsubscribe();
  }, [user]);

  // Suscribirse a los mensajes de la conversación seleccionada
  useEffect(() => {
    let unsubscribe;
    if (selectedConversationId) {
      unsubscribe = subscribeToMessages(selectedConversationId, (msgs) => {
        setMessages(msgs);
        msgs.forEach(async (msg) => {
          if (!msg.isRead) {
            const docRef = doc(db, 'conversations', selectedConversationId, 'messages', msg.id);
            await updateDoc(docRef, { isRead: true });
          }
        });
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [selectedConversationId]);

  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (selectedConversationId) {
        const messagesRef = collection(db, 'conversations', selectedConversationId, 'messages');
        const q = query(messagesRef, where('isRead', '==', false));
        const querySnapshot = await getDocs(q);

        console.log(
          'Mensajes no leídos:',
          querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        querySnapshot.forEach(async (docSnap) => {
          console.log('Marcando mensaje como leído:', docSnap.id);
          await updateDoc(docSnap.ref, { isRead: true });
        });
      }
    };

    const inputElement = document.getElementById('message-input');
    if (inputElement) {
      inputElement.addEventListener('focus', markMessagesAsRead);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', markMessagesAsRead);
      }
    };
  }, [selectedConversationId]);

  // Seleccionar una conversación
  const handleSelectConversation = (convId) => {
    setSelectedConversationId(convId);
    setMessages([]);
  };

  // Enviar mensaje de texto
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    await sendMessage(selectedConversationId, user.uid, newMessage);
    setNewMessage('');
  };

  // Enviar archivo
  const handleSendFile = async (e) => {
    const file = e.target.files[0];
    if (file && selectedConversationId) {
      const downloadURL = await uploadChatFile(selectedConversationId, file);
      await sendMessage(selectedConversationId, user.uid, '', downloadURL);
    }
  };

  return (
    <div style={{ display: 'flex', height: '80vh', margin: '20px' }}>
      <ConversationList
        conversations={conversations}
        selectedConversationId={selectedConversationId}
        onSelectConversation={handleSelectConversation}
      />
      <div style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
        {selectedConversationId ? (
          <>
            <MessageList
              messages={messages}
              user={user}
            />
            <MessageInput
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              handleSendMessage={handleSendMessage}
              handleSendFile={handleSendFile}
            />
          </>
        ) : (
          <p style={{ margin: '20px' }}>Selecciona una conversación para ver los mensajes</p>
        )}
      </div>
    </div>
  );
}

export default ProfessionalChatPage;