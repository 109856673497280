import React, { useEffect, useRef } from 'react';
import ReadStatus from './ReadStatus';
import MessageReactions from './MessageReactions';
import './MessageList.css';

const MessageList = ({ messages, user, onDeleteMessage, onReactToMessage }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div style={{ flex: 1, borderBottom: '1px solid #ccc', overflowY: 'scroll', padding: '10px' }}>
      {messages.map((msg) => (
        <div
          key={msg.id}
          className="message"
          style={{
            textAlign: msg.senderId === user.uid ? 'right' : 'left',
            margin: '10px',
            padding: '5px',
            position: 'relative'
          }}
        >
            {msg.attachmentUrl ? (
              <div>
                <p><strong>{msg.senderId === user.uid ? 'Tú' : 'Cliente'}:</strong> {msg.text || 'Archivo adjunto'}</p>
                {msg.attachmentUrl.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                  <img src={msg.attachmentUrl} alt="adjunto" style={{ maxWidth: '150px' }} />
                ) : (
                  <a href={msg.attachmentUrl} target="_blank" rel="noopener noreferrer">Descargar archivo</a>
                )}
              </div>
            ) : (
              <p>
                <strong>{msg.senderId === user.uid ? 'Tú' : 'Cliente'}:</strong> {msg.text}
              </p>
            )}
            <ReadStatus isRead={msg.isRead} />
            {msg.senderId === user.uid && (
              <button onClick={() => onDeleteMessage(msg.id)}>Eliminar</button>
            )}
          </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;