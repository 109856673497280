import React, { useEffect, useRef } from 'react';
import ReadStatus from '../components/ReadStatus';
import './MessageListUser.css';

const MessageListUser = ({ messages, user, professionalName }) => {
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div className="message-list">
      {messages.map((msg) => (
        <div
          key={msg.id}
          className={`message ${msg.senderId === user.uid ? 'sent' : 'received'}`}
        >
          {msg.attachmentUrl ? (
            <div>
              <p><strong>{msg.senderId === user.uid ? '' : professionalName}</strong> {msg.text || 'Archivo adjunto'}</p>
              {msg.attachmentUrl.match(/\.(jpeg|jpg|gif|png)$/i) ? (
                <img src={msg.attachmentUrl} alt="adjunto" />
              ) : (
                <a href={msg.attachmentUrl} target="_blank" rel="noopener noreferrer">
                  Descargar archivo
                </a>
              )}
            </div>
          ) : (
            <p>
              <strong>{msg.senderId === user.uid ? '' : professionalName}</strong> {msg.text}
            </p>
          )}
          <ReadStatus isRead={msg.isRead} />
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageListUser;