import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import './ProfilePicture.css';

const ProfilePicture = ({ user, profilePic, setProfilePic }) => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (file && user) {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `profilePics/${user.uid}`);
        
        // Eliminar la foto de perfil anterior si existe
        if (profilePic) {
          try {
            const oldProfilePicRef = ref(storage, profilePic);
            await deleteObject(oldProfilePicRef);
            console.log('Old profile pic deleted:', profilePic);
          } catch (error) {
            if (error.code === 'storage/object-not-found') {
              console.log('Old profile pic does not exist.');
            } else {
              throw error;
            }
          }
        }

        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        const userDocRef = doc(db, 'users', user.uid);
        await setDoc(userDocRef, { profilePic: downloadURL }, { merge: true });
        setProfilePic(downloadURL);
        console.log('Profile Pic Updated:', downloadURL);
      } catch (error) {
        console.error('Error updating profile pic:', error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="profile-picture-form">
      <img src={profilePic} alt="Profile" className="profile-picture" />
      <input type="file" onChange={handleFileChange} className="file-input" />
      <button type="submit" className="submit-btn">Actualizar Foto de Perfil</button>
    </form>
  );
};

export default ProfilePicture;