import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, getProfessionalProfile, getReviews } from './firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './ProfessionalProfile.css';
import ProfilePicture from './professionalprofilescript/ProfilePicture';
import Reviews from './professionalprofilescript/Reviews';
import Comments from './professionalprofilescript/Comments';
import Gallery from './professionalprofilescript/Gallery';
import ProfileDetailProfesional from './professionalprofilescript/ProfileDetailProfesional';
import ConfigPanel from './professionalprofilescript/ConfigPanel';

const ProfessionalProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profileCreationDate, setProfileCreationDate] = useState('');
  const [servicesCompleted, setServicesCompleted] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [isVerified, setIsVerified] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [userName, setUserName] = useState('');
  const [minReservation, setMinReservation] = useState('');
  const [repeatClients, setRepeatClients] = useState(0);
  const [showConfigPanel, setShowConfigPanel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfileAndReservations = async () => {
      let attempts = 0;
      while (!auth.currentUser && attempts < 10) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        attempts++;
      }

      const user = auth.currentUser;
      if (!user) {
        navigate('/login'); // Redirigir al usuario a la página de inicio de sesión si no está autenticado
        return;
      }

      // Verificar si el usuario es un profesional
      const professionalDocRef = doc(db, 'professionals', user.uid);
      const professionalDoc = await getDoc(professionalDocRef);
      if (!professionalDoc.exists()) {
        navigate('/'); // Redirigir al usuario a la página principal si no es un profesional
        return;
      }

      console.log('Usuario autenticado:', user);
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.name || user.email.split('@')[0]);
          if (!userData.name) {
            await updateDoc(userDocRef, { name: user.email.split('@')[0] });
          }
        } else {
          await setDoc(userDocRef, { name: user.email.split('@')[0] });
          setUserName(user.email.split('@')[0]);
        }

        const professionalProfile = await getProfessionalProfile(user.uid);
        console.log('Perfil profesional:', professionalProfile);
        setProfile(professionalProfile);
        setProfileCreationDate(professionalProfile.creationDate || '');
        setServicesCompleted(professionalProfile.servicesCompleted || 0);
        setAverageRating(professionalProfile.averageRating || 0);
        setIsVerified(professionalProfile.isVerified || false);
        setMinReservation(professionalProfile.minReservation || '30');
        setRepeatClients(professionalProfile.repeatClients || 0);

        const userReviews = await getReviews(user.uid);
        setReviews(userReviews);
      } catch (error) {
        console.error('Error al cargar el perfil profesional:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndReservations();
  }, [navigate]);

  if (loading) {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
        <p>Cargando...</p>
      </div>
    );
  }

  if (!profile) {
    return <p>No se encontró el perfil profesional.</p>;
  }

  return (
    <div className="profile-container">
      <div className="profile-summary">
        <ProfilePicture profile={profile} setProfile={setProfile} setShowConfigPanel={setShowConfigPanel} userName={userName} profileCreationDate={profileCreationDate} servicesCompleted={servicesCompleted} averageRating={averageRating} isVerified={isVerified} />
      </div>
      <div className="profile-details">
        <ProfileDetailProfesional profile={profile} />
      </div>
      <div className="gallery">
        <Gallery userId={auth.currentUser.uid} />
      </div>
      <div className="reviews">
        <Reviews reviews={reviews} />
      </div>
      <div className="comments">
        <Comments repeatClients={repeatClients} />
      </div>
      {showConfigPanel && <ConfigPanel profile={profile} setProfile={setProfile} handleClose={() => setShowConfigPanel(false)} />}
    </div>
  );
};

export default ProfessionalProfile;