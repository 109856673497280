import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getProfessionalProfile } from '../firebase';
import DetailReservaSpinner from './DetailReservaSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../firebase';
import { ChatContext } from '../chat/ChatContext';
import './DetailReservaProfile.css';

const DetailReservaProfile = ({ professionalId }) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = auth.currentUser;
  const { setProfessionalId } = useContext(ChatContext);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const professionalProfile = await getProfessionalProfile(professionalId);
        setProfile(professionalProfile || null);
      } catch (error) {
        console.error("Error fetching profile:", error);
        setProfile(null);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [professionalId]);

  const handleStartChat = () => {
    // 1) Forzar un cambio de professionalId para que el chat se "reinicie"
    setProfessionalId(null);

    // 2) En el siguiente ciclo, volvemos a asignar el original
    setTimeout(() => {
      setProfessionalId(professionalId);
    }, 0);
  };

  if (loading) {
    return <DetailReservaSpinner />;
  }

  if (!profile) {
    return (
      <div>
        <p>No se encontró el perfil profesional.</p>
        <Link to="/professionals">Volver a la lista de profesionales</Link>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-image-container">
        {profile.imageUrl && (
          <img src={profile.imageUrl} alt={profile.name} className="profile-image" />
        )}
      </div>
      <div className="profile-info">
        <h2 className="profile-name">
          {profile.name || 'Sin nombre'}
          <FontAwesomeIcon icon={faCheckCircle} className="profile-verified" />
        </h2>
        <p className="profile-rating">
          ⭐ {profile.averageRating !== undefined && profile.averageRating !== null
            ? `${profile.averageRating.toFixed(1)}`
            : 'Sin valoraciones'}
          <span className="profile-reviews">({profile.reviews || 0} Valoraciones)</span>
        </p>
        {user && (
          <div className="professional-detail-message-chat-section">
          <button onClick={handleStartChat} className="professional-detail-message-start-chat-button">
            <FontAwesomeIcon icon={faCheckCircle} className="professional-detail-message-icon" />
            Enviar mensaje
          </button>
        </div>
        
        
        )}
      </div>

      <div className="profile-biography">
        <h3 className="biography-title">Sobre mí</h3>
        <p>{profile.bio || 'Sin biografía disponible.'}</p>
      </div>
    </div>
  );
};

export default DetailReservaProfile;