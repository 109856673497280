// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
// Importamos el ChatProvider
import { ChatProvider } from './chat/ChatContext';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ChatProvider>
    <App />
  </ChatProvider>
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(registration => {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, err => {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
