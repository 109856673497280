import { db, storage } from './firebase';
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
  query,
  where,
  orderBy,
  onSnapshot,
  Timestamp
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

/**
 * Crea o retorna la conversación entre un usuario y un profesional.
 * Si ya existe, retorna el ID de la conversación existente.
 *
 * @param {string} userId - UID del usuario (cliente).
 * @param {string} professionalId - UID del profesional.
 * @returns {Promise<string>} - ID de la conversación.
 */
export const createOrGetConversation = async (userId, professionalId) => {
  try {
    const q = query(
      collection(db, 'conversations'),
      where('userId', '==', userId),
      where('professionalId', '==', professionalId)
    );
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const existingConversation = querySnapshot.docs[0];
      return existingConversation.id;
    } else {
      const conversationRef = await addDoc(collection(db, 'conversations'), {
        userId,
        professionalId,
        lastMessage: '',
        updatedAt: Timestamp.now(),
      });
      return conversationRef.id;
    }
  } catch (error) {
    console.error('Error creando o recuperando conversación:', error);
    throw error;
  }
};

/**
 * Envía un mensaje a la subcolección 'messages' de una conversación.
 *
 * @param {string} conversationId - ID de la conversación.
 * @param {string} senderId - UID de quien envía (usuario o profesional).
 * @param {string} text - Texto del mensaje.
 * @param {string} [attachmentUrl] - URL de archivo adjunto (opcional).
 */
export const sendMessage = async (conversationId, senderId, text, attachmentUrl = '') => {
  try {
    await addDoc(collection(db, 'conversations', conversationId, 'messages'), {
      text,
      senderId,
      timestamp: Timestamp.now(),
      attachmentUrl,
    });

    await updateDoc(doc(db, 'conversations', conversationId), {
      lastMessage: text || 'Archivo adjunto',
      updatedAt: Timestamp.now(),
    });
  } catch (error) {
    console.error('Error enviando mensaje:', error);
    throw error;
  }
};

/**
 * Escucha en tiempo real los mensajes de una conversación.
 *
 * @param {string} conversationId - ID de la conversación.
 * @param {function} callback - Función que recibe los mensajes actualizados.
 * @returns {function} - Función para desuscribirse.
 */
export const subscribeToMessages = (conversationId, callback) => {
  const messagesRef = collection(db, 'conversations', conversationId, 'messages');
  const messagesQuery = query(messagesRef, orderBy('timestamp', 'asc'));

  const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
    const messages = snapshot.docs.map((docSnap) => ({
      id: docSnap.id,
      ...docSnap.data(),
    }));
    callback(messages);
  });

  return unsubscribe;
};

/**
 * Sube un archivo a Firebase Storage y retorna la URL de descarga.
 *
 * @param {string} conversationId - ID de la conversación.
 * @param {File} file - El archivo a subir.
 * @returns {Promise<string>} - URL pública del archivo subido.
 */
export const uploadChatFile = async (conversationId, file) => {
  const uniqueFileName = `${uuidv4()}_${file.name}`;
  const storageRefPath = `chatFiles/${conversationId}/${uniqueFileName}`;
  const storageRef = ref(storage, storageRefPath);

  await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(storageRef);
  return downloadURL;
};
