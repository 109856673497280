import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams } from 'react-router-dom';
import { auth, db } from '../firebase';
import {
  createOrGetConversation,
  subscribeToMessages,
  sendMessage,
  uploadChatFile,
} from '../chatfunctions';
import { deleteDoc, updateDoc, getDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import MessageListUser from './componentsuser/MessageListUser';
import MessageInputBar from './componentsuser/MessageInputBar';
import ProfessionalListUser from './componentsuser/ProfessionalList';
import BackToProfessionalsButton from './componentsuser/BackToProfessionalsButton';
import CustomScroll from './componentsuser/CustomScroll';
import './UserChatPageMobile.css'; // Importa el archivo CSS

function UserChatPageMobile() {
  const { professionalId } = useParams();
  const [user] = useAuthState(auth);
  const [conversationId, setConversationId] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [professionals, setProfessionals] = useState([]);
  const [selectedProfessionalId, setSelectedProfessionalId] = useState(professionalId);
  const [view, setView] = useState('professionals'); // Estado para manejar la vista actual

  useEffect(() => {
    const fetchConversation = async () => {
      if (user && selectedProfessionalId) {
        const convId = await createOrGetConversation(user.uid, selectedProfessionalId);
        setConversationId(convId);
      }
    };
    fetchConversation();
  }, [user, selectedProfessionalId]);

  useEffect(() => {
    const fetchProfessionals = async () => {
      if (user) {
        const convRef = collection(db, 'conversations');
        const q = query(convRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const profs = [];
        for (const docSnap of querySnapshot.docs) {
          const data = docSnap.data();
          const professionalDoc = await getDoc(doc(db, 'users', data.professionalId));
          if (professionalDoc.exists()) {
            profs.push({
              id: data.professionalId,
              name: professionalDoc.data().name,
            });
          }
        }
        setProfessionals(profs);
      }
    };
    fetchProfessionals();
  }, [user]);

  useEffect(() => {
    let unsubscribe;
    if (conversationId) {
      unsubscribe = subscribeToMessages(conversationId, (msgs) => {
        setMessages(msgs);
      });

      const markMessagesAsRead = async () => {
        const messagesRef = collection(db, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, where('isRead', '==', false));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { isRead: true });
        });
      };
      markMessagesAsRead();
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [conversationId]);

  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (conversationId) {
        const messagesRef = collection(db, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, where('isRead', '==', false));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { isRead: true });
        });
      }
    };

    const inputElement = document.getElementById('message-input');
    if (inputElement) {
      inputElement.addEventListener('focus', markMessagesAsRead);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', markMessagesAsRead);
      }
    };
  }, [conversationId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    await sendMessage(conversationId, user.uid, newMessage);
    setNewMessage('');
  };

  const handleSendFile = async (e) => {
    const file = e.target.files[0];
    if (file && conversationId) {
      const downloadURL = await uploadChatFile(conversationId, file);
      await sendMessage(conversationId, user.uid, '', downloadURL);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    console.log('Deleting message with ID:', messageId); // Debugging
    await deleteDoc(doc(db, 'conversations', conversationId, 'messages', messageId));
  };

  const handleReactToMessage = async (messageId, emoji) => {
    const messageRef = doc(db, 'conversations', conversationId, 'messages', messageId);
    const messageDoc = await getDoc(messageRef);
    if (messageDoc.exists()) {
      const messageData = messageDoc.data();
      const reactions = messageData.reactions || [];
      reactions.push(emoji);
      await updateDoc(messageRef, { reactions });
    }
  };

  const handleSelectProfessional = (professionalId) => {
    setSelectedProfessionalId(professionalId);
    setView('conversation');
  };

  const handleBackToProfessionals = () => {
    setView('professionals');
  };

  return (
    <div className="chat-container-mobile">
      <div className="chat-content">
        {view === 'professionals' ? (
          <ProfessionalListUser
            professionals={professionals}
            onSelectProfessional={handleSelectProfessional}
          />
        ) : (
          <div className="chat-messages">
            <div className="chat-controls">
              <BackToProfessionalsButton onBack={handleBackToProfessionals} />
            </div>
            {selectedProfessionalId ? (
              <>
                <MessageListUser
                  messages={messages}
                  user={user}
                  onDeleteMessage={handleDeleteMessage}
                  onReactToMessage={handleReactToMessage}
                />
                <MessageInputBar
                  newMessage={newMessage}
                  setNewMessage={setNewMessage}
                  handleSendMessage={handleSendMessage}
                  handleSendFile={handleSendFile}
                />
              </>
            ) : (
              <p style={{ margin: '20px' }}>Selecciona un profesional para ver los mensajes</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default UserChatPageMobile;
