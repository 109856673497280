import React from 'react';
import StarRating from './StarRating';
import './RatingsSummary.css';

const RatingsSummary = ({ summary }) => {
  const { overallRating, totalReviews, categories, comments } = summary;

  const getRatingDescription = (rating) => {
    if (rating >= 4.5) return 'Excepcional';
    if (rating >= 4.0) return 'Muy Bueno';
    if (rating >= 3.0) return 'Bueno';
    if (rating >= 2.0) return 'Regular';
    return 'Malo';
  };

  return (
    <div className="ratings-summary">
      <div className="overall-rating">
        <div className="rating-value">
          {overallRating.toFixed(1)}
          <span className="star">★</span>
        </div>
        <div className="rating-description">{getRatingDescription(overallRating)}</div>
        <div className="total-reviews">({totalReviews} Valoraciones)</div>
      </div>
      <div className="categories">
        {categories.map((category) => (
          <div key={category.name} className="category">
            <span className="category-name">{category.name}</span>
            <div className="progress-bar">
              <div className="progress" style={{ width: `${(category.rating / 5) * 100}%` }}></div>
            </div>
            <span className="category-rating">{category.rating.toFixed(1)}</span>
          </div>
        ))}
      </div>
      <div className="comments">
        {comments.map((comment) => (
          <div key={comment.id} className="comment">
            <img src={comment.userPhotoURL} alt={comment.userName} className="user-photo" />
            <div className="comment-content">
              <div className="comment-header">
                <span className="user-name">{comment.userName}</span>
                <span className="comment-date">{comment.date}</span>
              </div>
              <div className="comment-text">{comment.text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingsSummary;