import React, { useEffect, useState } from 'react';
import './MessageInputBar.css';

const MessageInputBar = ({ newMessage, setNewMessage, handleSendMessage, handleSendFile }) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  useEffect(() => {
    // Función para detectar si el teclado está visible
    const handleResize = () => {
      // Verifica si la altura de la ventana ha cambiado
      if (window.innerHeight < document.documentElement.clientHeight) {
        setIsKeyboardVisible(true);
      } else {
        setIsKeyboardVisible(false);
      }
    };

    // Añadir el listener para cuando la ventana cambie de tamaño
    window.addEventListener('resize', handleResize);

    // Limpieza del listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Si el teclado está visible, desplazar la barra de entrada hacia arriba
    if (isKeyboardVisible) {
      document.querySelector('.message-input-bar').style.transform = 'translateY(-150px)'; // Ajusta el valor según sea necesario
    } else {
      document.querySelector('.message-input-bar').style.transform = 'translateY(0)';
    }
  }, [isKeyboardVisible]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isKeyboardVisible) {
      handleSendMessage();
    }
  };

  return (
    <div className="message-input-bar">
      <input
        type="text"
        id="message-input"
        className="message-input"
        placeholder="Escribe tu mensaje..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <input
        type="file"
        id="file-input"
        className="file-input"
        onChange={handleSendFile}
        style={{ display: 'none' }}
      />
      <label htmlFor="file-input" className="file-input-button">
        📎
      </label>
      <button className="send-button" onClick={handleSendMessage}>
        Enviar
      </button>
    </div>
  );
};

export default MessageInputBar;