import React, { useState, useEffect } from 'react';
import { getGalleryImages } from '../firebase';
import './DetailReservaGallery.css';

const DetailReservaGallery = ({ professionalId }) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchGalleryImages = async () => {
      try {
        const galleryImages = await getGalleryImages(professionalId);
        setImages(galleryImages);
      } catch (error) {
        console.error('Error al obtener las imágenes de la galería:', error);
      }
    };

    fetchGalleryImages();
  }, [professionalId]);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="detail-reserva-gallery">
      <h3>Galería</h3>
      <div className="gallery-images">
        {images.length > 0 ? (
          images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Trabajo ${index + 1}`}
              className="gallery-image"
              onClick={() => openModal(image)}
            />
          ))
        ) : (
          <p>No hay imágenes disponibles.</p>
        )}
      </div>

      {selectedImage && (
        <div className={`modal ${selectedImage ? 'active' : ''}`} onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedImage} alt="Imagen ampliada" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailReservaGallery;
