import React from 'react';
import './Reservations.css';

const Reservations = ({ reservations }) => {
  return (
    <div className="reservations">
      <h2>Reservas</h2>
      {reservations.length === 0 ? (
        <p>No tienes reservas.</p>
      ) : (
        <ul className="reservation-list">
          {reservations.map((reservation) => (
            <li key={reservation.id} className="reservation-item">
              <p>Profesional: {reservation.professionalName || 'Desconocido'}</p>
              <p>Horas Contratadas: {reservation.hours !== 'N/A' ? reservation.hours : 'N/A'}</p>
              <p>Total: {reservation.price !== 'N/A' ? `$${reservation.price}` : 'N/A'}</p>
              <p>Fecha: {reservation.date || 'N/A'}</p>
              <p>Hora: {reservation.time || 'N/A'}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Reservations;