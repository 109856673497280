// src/chat/ChatContext.jsx
import React, { createContext, useState } from 'react';

export const ChatContext = createContext();

export function ChatProvider({ children }) {
  // Solo manejamos el profesional con el que se está chateando
  const [professionalId, setProfessionalId] = useState(null);

  return (
    <ChatContext.Provider
      value={{
        professionalId,
        setProfessionalId,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
}
