import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useParams, useLocation } from 'react-router-dom';
import { auth, db } from '../firebase';
import {
  createOrGetConversation,
  subscribeToMessages,
  sendMessage,
  uploadChatFile,
} from '../chatfunctions';
import {
  updateDoc,
  getDoc,
  doc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import MessageListUser from './componentsuser/MessageListUser';
import MessageInputBar from './componentsuser/MessageInputBar';
import ProfessionalListUser from './componentsuser/ProfessionalList';
import BackToProfessionalsButton from './componentsuser/BackToProfessionalsButton';
import CustomScroll from './componentsuser/CustomScroll';
import './UserChatPage.css';

function UserChatPage(props) {
  // Combina lo que venga de props con lo que pueda venir de la URL
  const { professionalId: paramProfessionalId } = useParams();
  const professionalId = props.professionalId || paramProfessionalId;

  const [user] = useAuthState(auth);

  // ID de la conversación en Firestore
  const [conversationId, setConversationId] = useState('');

  // Lista de mensajes y mensaje nuevo
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');

  // Lista de profesionales y búsqueda
  const [searchResults, setSearchResults] = useState([]);
  const [professionals, setProfessionals] = useState([]);

  // Profesional seleccionado y vista actual
  const [selectedProfessionalId, setSelectedProfessionalId] = useState(professionalId);
  const [view, setView] = useState('professionals');

  // Controla si el panel está abierto/cerrado (para transición)
  const [isChatOpen, setIsChatOpen] = useState(false);

  // Nombre del profesional que se mostrará en la conversación
  const [professionalName, setProfessionalName] = useState('');

  // ----------------------------------------------
  // 1. Carga o crea la conversación con el profesional seleccionado
  // ----------------------------------------------
  useEffect(() => {
    async function fetchConversation() {
      if (user && selectedProfessionalId) {
        const convId = await createOrGetConversation(user.uid, selectedProfessionalId);
        setConversationId(convId);

        // Obtener el nombre del profesional
        const professionalDoc = await getDoc(doc(db, 'users', selectedProfessionalId));
        if (professionalDoc.exists()) {
          setProfessionalName(professionalDoc.data().name);
        }
      }
    }
    fetchConversation();
  }, [user, selectedProfessionalId]);

  // ----------------------------------------------
  // 2. Si llega un professionalId, abrimos el chat y ponemos la vista "conversation".
  //    Este efecto se dispara siempre que professionalId cambie.
  // ----------------------------------------------
  useEffect(() => {
    if (professionalId) {
      setIsChatOpen(true);
      setView('conversation');
      setSelectedProfessionalId(professionalId);
    }
  }, [professionalId]);

  // ----------------------------------------------
  // 3. Si viene un professionalId desde el state de React Router (ej: navigate con state)
  // ----------------------------------------------
  const location = useLocation();
  const { professionalId: routeProfessionalId } = location.state || {};

  useEffect(() => {
    if (routeProfessionalId) {
      setIsChatOpen(true);
      setSelectedProfessionalId(routeProfessionalId);
      setView('conversation');
    }
  }, [routeProfessionalId]);

  // ----------------------------------------------
  // 4. Cargar profesionales con los que haya conversaciones
  // ----------------------------------------------
  useEffect(() => {
    async function fetchProfessionals() {
      if (user) {
        const convRef = collection(db, 'conversations');
        const q = query(convRef, where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const profs = [];

        for (const docSnap of querySnapshot.docs) {
          const data = docSnap.data();
          const professionalDoc = await getDoc(doc(db, 'users', data.professionalId));
          if (professionalDoc.exists()) {
            profs.push({
              id: data.professionalId,
              name: professionalDoc.data().name,
            });
          }
        }
        setProfessionals(profs);
      }
    }
    fetchProfessionals();
  }, [user]);

  // ----------------------------------------------
  // 5. Suscribirse a los mensajes de la conversación actual
  // ----------------------------------------------
  useEffect(() => {
    let unsubscribe;
    if (conversationId) {
      unsubscribe = subscribeToMessages(conversationId, (msgs) => {
        setMessages(msgs);
      });

      // Marcamos como leídos todos los mensajes no leídos
      const markMessagesAsRead = async () => {
        const messagesRef = collection(db, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, where('isRead', '==', false));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { isRead: true });
        });
      };
      markMessagesAsRead();
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [conversationId]);

  // ----------------------------------------------
  // 6. Marcar mensajes como leídos cuando se hace focus en el input
  // ----------------------------------------------
  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (conversationId) {
        const messagesRef = collection(db, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, where('isRead', '==', false));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { isRead: true });
        });
      }
    };

    const inputElement = document.getElementById('message-input');
    if (inputElement) {
      inputElement.addEventListener('focus', markMessagesAsRead);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', markMessagesAsRead);
      }
    };
  }, [conversationId]);

  // ----------------------------------------------
  // 7. Enviar mensaje de texto
  // ----------------------------------------------
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
    await sendMessage(conversationId, user.uid, newMessage);
    setNewMessage('');
  };

  // ----------------------------------------------
  // 8. Enviar archivo (imagen, etc.)
  // ----------------------------------------------
  const handleSendFile = async (e) => {
    const file = e.target.files[0];
    if (file && conversationId) {
      const downloadURL = await uploadChatFile(conversationId, file);
      await sendMessage(conversationId, user.uid, '', downloadURL);
    }
  };

  // ----------------------------------------------
  // 9. Seleccionar un profesional de la lista
  // ----------------------------------------------
  const handleSelectProfessional = (professionalId) => {
    setSelectedProfessionalId(professionalId);
    setView('conversation');
    setIsChatOpen(true);
  };

  // ----------------------------------------------
  // 10. Botón "Volver" a la lista de profesionales
  // ----------------------------------------------
  const handleBackToProfessionals = () => {
    setView('professionals');
  };

  // ----------------------------------------------
  // Render
  // ----------------------------------------------
  return (
    <div className={`chat-container ${isChatOpen ? 'open' : ''}`}>
      {/* Al hacer click en el header, togglear entre abrir/cerrar */}
      <div className="chat-header" onClick={() => setIsChatOpen(!isChatOpen)}>
        Chat con Profesionales
      </div>

      {isChatOpen && (
        <div className="chat-content">
          {view === 'professionals' ? (
            <ProfessionalListUser
              professionals={professionals}
              onSelectProfessional={handleSelectProfessional}
            />
          ) : (
            <div className="chat-messages">
              <div className="chat-controls">
                <BackToProfessionalsButton onBack={handleBackToProfessionals} />
              </div>

              {selectedProfessionalId ? (
                <>
                  <MessageListUser
                    messages={messages}
                    user={user}
                    professionalName={professionalName}
                  />
                  <MessageInputBar
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                    handleSendFile={handleSendFile}
                  />
                </>
              ) : (
                <p style={{ margin: '20px', textAlign: 'center' }}>
                  Selecciona un profesional para ver los mensajes
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default UserChatPage;
