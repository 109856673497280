import React from 'react';

const ConversationList = ({ conversations, selectedConversationId, onSelectConversation }) => {
  return (
    <div style={{ width: '30%', borderRight: '1px solid #ccc', overflowY: 'auto' }}>
      <h3 style={{ textAlign: 'center' }}>Mis Conversaciones</h3>
      {conversations.map((conv) => (
        <div
          key={conv.id}
          style={{
            padding: '10px',
            cursor: 'pointer',
            backgroundColor: conv.id === selectedConversationId ? '#eaeaea' : '#fff',
            borderBottom: '1px solid #ddd'
          }}
          onClick={() => onSelectConversation(conv.id)}
        >
          <p><strong>Usuario:</strong> {conv.userName}</p>
          <p><strong>Último mensaje:</strong> {conv.lastMessage}</p>
          <p><strong>Actualizado:</strong> {conv.updatedAt}</p>
        </div>
      ))}
    </div>
  );
};

export default ConversationList;