import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getReviews, subscribeToReviews } from '../firebase';
import { formatDistanceToNow } from 'date-fns';
import RatingsSummary from './RatingsSummary';
import './DetailReservaReviews.css';

const DetailReservaReviews = () => {
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const initialReviews = await getReviews(id);
      setReviews(initialReviews || []);
    };

    fetchReviews();

    const unsubscribe = subscribeToReviews(id, (newReviews) => {
      setReviews(newReviews || []);
    });

    return () => unsubscribe();
  }, [id]);

  const calculateOverallRating = (reviews) => {
    if (reviews.length === 0) {
      return {
        overallRating: 0,
        serviceRating: 0,
        punctualityRating: 0,
        friendlinessRating: 0,
        valueRating: 0,
        professionalismRating: 0,
      };
    }

    const totalRatings = reviews.reduce((acc, review) => {
      return {
        overallRating: acc.overallRating + review.overallRating,
        serviceRating: acc.serviceRating + review.serviceRating,
        punctualityRating: acc.punctualityRating + review.punctualityRating,
        friendlinessRating: acc.friendlinessRating + review.friendlinessRating,
        valueRating: acc.valueRating + review.valueRating,
        professionalismRating: acc.professionalismRating + review.professionalismRating,
      };
    }, {
      overallRating: 0,
      serviceRating: 0,
      punctualityRating: 0,
      friendlinessRating: 0,
      valueRating: 0,
      professionalismRating: 0,
    });

    const totalReviews = reviews.length;
    return {
      overallRating: totalRatings.overallRating / totalReviews || 0,
      serviceRating: totalRatings.serviceRating / totalReviews || 0,
      punctualityRating: totalRatings.punctualityRating / totalReviews || 0,
      friendlinessRating: totalRatings.friendlinessRating / totalReviews || 0,
      valueRating: totalRatings.valueRating / totalReviews || 0,
      professionalismRating: totalRatings.professionalismRating / totalReviews || 0,
    };
  };

  const overallRatings = calculateOverallRating(reviews);

  const summaryData = {
    overallRating: overallRatings.overallRating,
    totalReviews: reviews.length,
    categories: [
      { name: 'Servicio', rating: overallRatings.serviceRating },
      { name: 'Calidad / Precio', rating: overallRatings.valueRating },
      { name: 'Puntualidad', rating: overallRatings.punctualityRating },
      { name: 'Amabilidad', rating: overallRatings.friendlinessRating },
      { name: 'Profesionalidad', rating: overallRatings.professionalismRating },
    ],
    comments: reviews
      .filter(review => review.comment && review.comment.trim() !== '')
      .map(review => ({
        id: review.id,
        userName: review.userName,
        userPhotoURL: review.userPhotoURL,
        date: formatDistanceToNow(new Date(review.timestamp.toDate()), { addSuffix: true }),
        text: review.comment,
      })),
  };

  return (
    <>
      {reviews.length === 0 ? (
        <p>No hay reseñas disponibles.</p>
      ) : (
        <RatingsSummary summary={summaryData} />
      )}
    </>
  );
};

export default DetailReservaReviews;