import React from 'react';
import './BackToProfessionalsButton.css';

const BackToProfessionalsButton = ({ onBack }) => {
  return (
    <button className="back-button" onClick={onBack}>
      ← Volver a Profesionales
    </button>
  );
};

export default BackToProfessionalsButton;