import React, { useRef } from 'react';

const MessageInput = ({ newMessage, setNewMessage, handleSendMessage, handleSendFile }) => {
  const fileInputRef = useRef(null);

  const openFileChooser = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div style={{ padding: '10px' }}>
      <input
        id="message-input"
        type="text"
        placeholder="Escribe tu mensaje..."
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        style={{ width: '65%', marginRight: '5px' }}
      />
      <button onClick={handleSendMessage}>Enviar</button>
      <button style={{ marginLeft: '10px' }} onClick={openFileChooser}>
        Adjuntar archivo
      </button>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleSendFile}
      />
    </div>
  );
};

export default MessageInput;