import React, { useState, useEffect } from 'react';
import './ReadStatus.css';

const ReadStatus = ({ isRead }) => {
  // Mantén un estado local para forzar la actualización cuando cambie isRead
  const [currentReadStatus, setCurrentReadStatus] = useState(isRead);

  useEffect(() => {
    setCurrentReadStatus(isRead);
  }, [isRead]);

  return (
    <span className={`read-status ${currentReadStatus ? 'read' : 'unread'}`}>
      {currentReadStatus ? '✔✔' : '✔'}
    </span>
  );
};

export default ReadStatus;